import React, { useState, useEffect } from "react";
import { Divider, Grid, Button } from "@material-ui/core";
import { useUIContext } from "../../../ContextLib/contextHooks";
import SnackBarHelper from "../Components/Helper/SnackBarHelper";
import MaterialTableSelect from "../Components/Common/MaterialTableSelect";
import { PmsGridSort } from "../Util/PmsHelper";
import MaterialDataGrid from "../Components/Common/MaterialDataGrid";
import {
  GetPmsBuildVersions,
  GetPmsCoverConfigSettingsTemplate,
  GetPmsPropertyConfig,
} from "../Util/pmsIntegrationApiUtil";
import PmsDocumentationJsonTemplate from "./PmsDocumentationJsonTemplate";
import PmsDocumentationModal from "./PmsDocumentationModal";

export default function PmsDocumentation() {
  const ui = useUIContext("v5filters");

  const [showModal, setShowModal] = useState(false);
  const [showModalRow, setShowModalRow] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [selectedRowData, setSelectedRowData] = useState({});
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const [mockData, setMockData] = useState({
    selectedJob: "",
    selectedPms: "",
    selectedVersion: "",
    selectedSelector: "All Properties",
    datadata: [],
    isSelectorReadOnly: true,
  });

  const [settingTemplate, setSettingTemplate] = useState("");
  const [propertyData, setPropertyData] = useState([]);
  const [buildVersions, setBuildVersions] = useState([]);

  const columns = [
    {
      field: "pmsCategory",
      headerName: "PMS",
      width: 110,
      sortComparator: PmsGridSort,
      cellClassName: "sticky-column",
    },
    {
      field: "property",
      headerName: "Property",
      width: 200,
      sortComparator: PmsGridSort,
      cellClassName: "pms-property",
      renderCell: (params) => (
        <label
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onClickColumnProperty(params)}
        >
          {params.value}
        </label>
      ),
    },

    {
      field: "defaultValue",
      headerName: "Default Value",
      width: 450,
    },
    {
      field: "templateDefault",
      headerName: "Template Default",
      width: 400,
    },
    {
      field: "flagDescription",
      headerName: "Flag Description",
      width: 550,
    },
    {
      field: "dataType",
      headerName: "Data Type",
      width: 150,
      sortComparator: PmsGridSort,
    },
    {
      field: "developerUsageOnly",
      headerName: "Dev Only?",
      width: 150,
      sortComparator: PmsGridSort,
      type: "boolean",
    },
  ];

  const onClickColumnProperty = (data) => {
    setShowModalRow(true);
    setSelectedRowData(data.row);
    console.error("1", data.row)
  };

  const HandleUpdateSnackBar = (
    severity,
    message,
    doNotOpenSnackBar = true
  ) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);

    if (!doNotOpenSnackBar) setOpenSnackBar(true);
  };

  const _postGetConfigTemplate = async () => {
    if (mockData.selectedPms == "") {
      HandleUpdateSnackBar("error", `Please select a PMS first.`, false);
      return;
    }

    ui.ShowOverlay();
    const promises = [
      GetPmsCoverConfigSettingsTemplate(
        mockData.selectedPms,
        mockData.selectedVersion
      )
        .then((resp) => {
          const { coverConfigSettingsTemplate } = resp.data;
          if (
            coverConfigSettingsTemplate == null ||
            coverConfigSettingsTemplate == ""
          ) {
            setSettingTemplate("");
            HandleUpdateSnackBar("warning", `No records found`, false);
            return;
          }

          setSettingTemplate(coverConfigSettingsTemplate);
          setShowModal(true);
        })
        .catch((error) =>
          HandleUpdateSnackBar(
            "error",
            `Error while getting the records: ${error}`,
            false
          )
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const _getDocumentationFromApi = async () => {


    if (mockData.selectedPms == "") {
      HandleUpdateSnackBar("error", `Please select a PMS first.`, false);
      return;
    }

    ui.ShowOverlay();
    const promises = [
      GetPmsPropertyConfig(mockData.selectedPms, mockData.selectedVersion)
        .then((resp) => {
          // if the properties is set to null.
          if (resp.data.properties == null) {
            setPropertyData([]);
            setMockData({
              ...mockData,
              datadata: [],
            });

            HandleUpdateSnackBar("warning", `No records found`, false);
            return;
          }

          resp.data.properties = resp.data.properties.map(property => {
              if (property.dataType.toLowerCase().includes("int")) {
                  return { ...property, dataType: "Integer" };
              }
              return property;
          });
          setPropertyData(resp.data.properties);
        
          const changeDropDown = PerformDropDownAction(resp.data.properties);

          setMockData((prevMockData) => ({
            ...prevMockData,
            datadata: changeDropDown,
          }));

         })
        .catch((error) =>
          HandleUpdateSnackBar(
            "error",
            `Error while getting the records: ${error}`,
            false
          )
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const setValue = (e, isCheckBox = false) => {
    const { name, value, checked } = e.target;
    let val = value;
    if (isCheckBox) val = checked;

    setMockData({
      ...mockData,
      [name]: val,
    });
  };

  function mergeProperties(array) {
    const merged = {};

    array.forEach((item) => {
      const { property, pmsCategory, defaultValue } = item;

      if (!merged[property]) {
        // Initialize if the property hasn't been seen yet
        merged[property] = { ...item };
      } else {
        // Merge default values if "Common"
        if (pmsCategory === "Common") {
          // Combine default values (append or customize merging logic)
          merged[property].defaultValue = combineDefaultValue(merged, item);
          merged[property].templateDefault = combineTemplateValue(merged, item, merged[property].templateDefault);

          merged[property].flagDescription = combineFlagDescription(
            merged,
            item
          );
        } else {
          // Replace with the non-Common entry
          merged[property] = {
            ...item,
            defaultValue: merged[property].defaultValue + "\n" + defaultValue,
          };
        }
      }
    });

    if (Object.values(merged).length > 0)
    {
      Object.values(merged).forEach((x) => {
        const { property } = x;
        if (x.defaultValue === null)
        {
          merged[property] = {...x,
            defaultValue: "null"
          };
        }
      });
    }

    console.error("11", merged)
    // Convert merged object back to an array
    return Object.values(merged);
  }
  function combineFlagDescription(merged, item) {
    const { property, defaultValue, flagDescription } = item;

    const firstElement = merged[property].flagDescription;

    const concatLine = "\n\n" + flagDescription;

    return firstElement + concatLine;
  }

  function combineTemplateValue(merged, item,templateValueMerge) {
    //item holds the current iteration of the loop.
    //where merge holds the details of the existing object we are building.
    const { property, defaultValue, templateValue } = item;

    if (merged[property].templateDefault !== "SAME AS DEFAULT") {
      let firstElement = "";
      let firstElementVal = "";
      let concatElement = "";
      let concatElementVal = "";

      // we concat and check first the value coming from the merge.
      if (
        templateValueMerge == undefined ||
        templateValueMerge == ""
      ) {
        //get the defaultValue if templateValue doesn't exist.
        firstElementVal = merged[property].defaultValue;
      } else {
        firstElementVal = templateValueMerge;
      }

      firstElement = firstElementVal.trim().split("\n").splice(0).join("\n").slice(0, -1).trimEnd() +",";

      // we now concat the template value of the existing loop
      if (templateValue == undefined || templateValue == "") {
        //get the defaultValue if templateValue doesn't exist.
        concatElementVal = defaultValue;
      } else {
        concatElementVal = templateValue;
      }

      concatElement =
        "\n" +
        concatElementVal
          .split("\n")
          .slice(1) // Removes the first line
          .join("\n"); // Joins the lines back together

      return firstElement.concat(concatElement);
    }
    else {
      return merged[property].templateDefault;
    }
  }

  function combineDefaultValue(merged, item) {
    const { property, defaultValue } = item;

    const firstElement = merged[property].defaultValue
    .trim().split("\n").splice(0).join("\n").slice(0, -1).trimEnd() +",";

    const concatLine =
      "\n" +
      defaultValue
        .split("\n")
        .slice(1) // Removes the first line
        .join("\n"); // Joins the lines back together



        // // firstElement = firstElementVal.trim().split("\n").splice(0).join("\n").slice(0, -1).trimEnd() +",";

        // // we now concat the template value of the existing loop
        // if (templateValue == undefined || templateValue == "") {
        //   //get the defaultValue if templateValue doesn't exist.
        //   concatElementVal = defaultValue;
        // } else {
        //   concatElementVal = templateValue;
        // }
  
        // concatElement =
        //   "\n" +
        //   concatElementVal
        //     .split("\n")
        //     .slice(1) // Removes the first line
        //     .join("\n"); // Joins the lines back together
  
        return firstElement.concat(concatLine);


    // return firstElement + "," + concatLine;
  }

  useEffect(() => {
    const changeDropDown = PerformDropDownAction();
  
    console.error("x", changeDropDown)
    setMockData((prevMockData) => ({
        ...prevMockData,
        datadata: changeDropDown,
      }));
  
    }, [mockData.selectedSelector]);

  const PerformDropDownAction = (data) => {
    const selector = mockData.selectedSelector.toLowerCase();
    if(data == null) data = propertyData;

    const processAndSetData = (filterCondition) => {
      let filteredData = data.filter(filterCondition);

      filteredData = filteredData.map((item) => ({
        ...item,
        defaultValue: item.defaultValue === null ? "null" : item.defaultValue,
      }));

      return filteredData;
      // setMockData((prevMockData) => ({
      //   ...prevMockData,
      //   datadata: filteredData,
      // }));
    };

    if (selector === "all properties") {
      const mergedProps = mergeProperties(data);
      return mergedProps;
      // setMockData((prevMockData) => ({
      //   ...prevMockData,
      //   datadata: mergedProps,
      // }));
      // return;
    }

    if (selector === "common properties") {
      return processAndSetData((item) => item.pmsCategory === "Common");
    }

    return processAndSetData((item) => item.pmsCategory !== "Common");
  }

  useEffect(() => {
    async function fetchData() {
      ui.ShowOverlay();
      const promises = [
        GetPmsBuildVersions()
          .then((resp) => {
            setBuildVersions(resp.data);
            setMockData({
              ...mockData,
              selectedVersion: resp.data[0],
            });
          })
          .catch((error) =>
            HandleUpdateSnackBar(
              "error",
              `Error while getting the records: ${error}`,
              false
            )
          )
          .finally(() => {
            ui.HideOverlay();
          }),
      ];
      await Promise.all(promises);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (mockData.selectedPms.toLowerCase() == "common") {
      setMockData({
        ...mockData,
        isSelectorReadOnly: true,
        selectedSelector: "Common Properties",
      });

      return;
    }

    setMockData({
      ...mockData,
      isSelectorReadOnly: false,
      // selectedSelector: "All Properties",
    });

    return;
  }, [mockData.selectedPms]);

  const closeModal = () => {
    setSettingTemplate("");
    setShowModal(false);
    setShowModalRow(false);
  };

  return (
    <>
      <PmsDocumentationJsonTemplate
        onCloseModal={() => closeModal()}
        showModal={showModal}
        jsonTemplate={settingTemplate}
      />
      <PmsDocumentationModal
        onCloseModal={() => closeModal()}
        showModal={showModalRow}
        rowValue={selectedRowData}
        // jsonTemplate={settingTemplate}
      />
      <SnackBarHelper
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        Severity={snackBarSeverity}
        AlertMessage={snackBarMessage}
      />
      <h1 className="pmstitle-title__wrapper">
        Blade JSON Config Documentation
      </h1>
      <div className="pms-ws__setting-wrapper">
        <Grid
          container
          alignItems="center"
          spacing={1}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid item sm={2}>
            <MaterialTableSelect
              data={[
                "Uprise",
                "Eyefinity",
                "CrystalPM",
                "Officemate",
                "Compulink",
                "Foxfire",
                "Maximeyes",
                "Revolution",
                "NextGen",
              ]}
              label="PMS"
              value={mockData.selectedPms || ""}
              name="selectedPms"
              onChange={(e) => setValue(e)}
              fullWidth={true}
            />
          </Grid>
          <Grid item sm={2}>
            <MaterialTableSelect
              data={buildVersions}
              label="Build Version"
              value={mockData.selectedVersion || ""}
              name="selectedVersion"
              onChange={(e) => setValue(e)}
              fullWidth={true}
            />
          </Grid>
          <Grid item sm={3}>
            <Button
              style={{ width: "230px" }}
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => _getDocumentationFromApi()}
            >
              Get Documentation
            </Button>
          </Grid>

          <Grid
            item
            sm={5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <MaterialTableSelect
              data={[
                "All Properties",
                "PMS Specific Properties",
                "Common Properties",
              ]}
              label="PMS Specific and/or Common Properties"
              value={mockData.selectedSelector || ""}
              name="selectedSelector"
              onChange={(e) => setValue(e)}
              fullWidth={true}
              readOnly={mockData.isSelectorReadOnly}
            />
            <Button
              style={{ width: "250px", marginLeft: "10px" }}
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => _postGetConfigTemplate()}
            >
              Show Template
            </Button>
          </Grid>
          <Grid item sm={12}>
            <Divider style={{ margin: "10px 0px" }} />
          </Grid>

          <Grid item sm={12}>
            <MaterialDataGrid
              tableHeight={5000}
              rowHeight={undefined}
              tableColumns={columns}
              tableData={mockData.datadata || []}
              showBorder={true}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
