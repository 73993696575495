import axios from 'axios';
import React , { useEffect, useRef, useState, createRef } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Switch from '@material-ui/core/Switch';

import { Box, Button, Divider, Grid, Icon, InputLabel, Typography } from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import './TwoWayText.scss';
import TwoWayEmoji from './components/TwoWayEmoji';
import Modal from '../../Components/Modal/Modal';
import CoverFilter from '../../ContextLib/CoreConsumer/Components/CoverLookup';
import { useCoverFiltersContext, useUIContext } from '../../ContextLib/contextHooks';
import { Severity } from '../../ContextLib/CoreConsumer/Components/SnackbarMessage';
import insertTextAtCursor from 'insert-text-at-cursor';

export default function TwoWayText() {
    let componentId = "TwoWayText";
    const ui = useUIContext(componentId);
    const [coverSelected, setCoverSelected] = useState(false);
    const [hasSystemMessages, setHasSystemMessages] = useState(false);
    const [hasCustomMessages, setHasCustomMessages] = useState(false);
    const [systemMessages, setSystemMessages] = useState([]);
    const [customMessages, setCustomMessages] = useState([]);
    const [showViewOnlyMsgModal, setShowViewOnlyMsgModal] = useState(false);
    const [viewOnlyMsgModalTitle, setViewOnlyMsgModalTitle] = useState("");
    const [viewOnlyMsgModalBody, setViewOnlyMsgModalBody] = useState("");
    const [createAction, setCreateAction] = useState(false);
    const [showCreateEditCustomTemplateModal,  setShowCreateEditCustomTemplateModal] = useState(false);
    const [templateIdToUpdate, setTemplateIdToUpdate] = useState(null);
    const [createEditCustomMsgTitle, setCreateEditCustomMsgTitle] = useState("");
    const [createEditCustomMsgMessage, setCreateEditCustomMsgMessage] = useState("");
    const createEditCustomMsgInput = useRef();
    const [characterCount, setCharacterCount] = useState(0);
    const [characterCountMax] = useState(320);
    let lastCoverKey = null;
    let availableTokens = {"LocationName" : "<<LOCATION_NAME>>" ,  "LocationAddress" : "<<LOCATION_ADDRESS>>", "ScheduleURL" : "<<SCHEDULE_URL>>",
                           "RescheduleURL" : "<<RESCHEDULE_URL>>", "RegistrationURL" : "<<REGISTRATION_URL>>", "FrameDreamURL" : "<<FRAME_DREAM_URL>>"}
    const filtersContext = useCoverFiltersContext(componentId, null, async (newContext) => {
    setSelectedCoverKey(newContext.coverKey);
      if (lastCoverKey != newContext.coverKey) {
          lastCoverKey = newContext.coverKey;
      }

      let ready = newContext.coverKey != 0;
      _handlePageDataLoad(ready);
      setCoverSelected(ready);
    });

    const [selectedCoverKey, setSelectedCoverKey] = useState(filtersContext.Values.coverKey);

    const _handlePageDataLoad = async (ready) => {
        if (ready) {
            _handleGetSystemMessages();
            _handleGetCustomMessages();
           // setCoverSelected(true);
        }
    };

    const _handleGetSystemMessages = async() => {
        ui.ShowOverlay();
        try {
            var request = {
                params: {
                  secretCode: `${process.env.REACT_APP_API_KEY}`,
                  coverKey: filtersContext.Values.coverKey
                //locale: navigator.language
                }
              }

              const url = `${process.env.REACT_APP_TWOWAYTEXT_API}/getSystemMessages`;
              const res = await axios.get(url, request);
              let d = res.data;
            
              if (res?.data.length > 0) { setSystemMessages(d); setHasSystemMessages(true); }
              else { 
                //ui.ShowSnackbar(`Locale culture-${navigator.language} has no System Messages`, Severity.warning);
                  setSystemMessages([]);
                  setHasSystemMessages(false); 
              }
        } catch {
            setHasSystemMessages(false); 
            ui.ShowSnackbar("Error in getting Default Messages", Severity.error);
        }
        ui.HideOverlay();
    };

    const _handleGetCustomMessages = async() => {
        ui.ShowOverlay();
        try {
            var request = {
                params: {
                  secretCode: `${process.env.REACT_APP_API_KEY}`,
                  coverKey: filtersContext.Values.coverKey
                }
              }

              const url = `${process.env.REACT_APP_TWOWAYTEXT_API}/getCustomMessages`;
              const res = await axios.get(url, request);
              let d = res.data;
            
              if (res?.data.length > 0) { setCustomMessages(d); setHasCustomMessages(true); }
              else { 
                //ui.ShowSnackbar(`Coverkey-${coverKey} has no Custom Messages. Please select another CoverKey.`, Severity.warning, 5000);
                  setCustomMessages([]);
                  setHasCustomMessages(false); 
              }
        } catch {
            setHasCustomMessages(false);
            ui.ShowSnackbar("Error in getting Custom Messages", Severity.error);
        }
        ui.HideOverlay();
    }

    const _handleOnClickViewSystemMessage = (template) => {
        setViewOnlyMsgModalTitle(template.name);
        setViewOnlyMsgModalBody(template.body);
        setShowViewOnlyMsgModal(true);
    }

    const _handleShowCreateEditCustomTemplateModal = (_createAction = false) => {
        setCreateAction(_createAction)
        setShowCreateEditCustomTemplateModal(true);
    }

    const _handleOnClickEditCustomMessage = (template) => {
        setTemplateIdToUpdate(template.templateId);
        setCreateEditCustomMsgTitle(template.name);
        setCreateEditCustomMsgMessage(template.body);
        _handleShowCreateEditCustomTemplateModal();
    }

    const _handleOnSubmitCreateEditCustomTemplate = async () => {
        ui.ShowOverlay()
        try {
            const apiKey = `${process.env.REACT_APP_API_KEY}`;
            var model = {
                coverKey: selectedCoverKey,
                templateId: templateIdToUpdate,
                name: createEditCustomMsgTitle,
                body: createEditCustomMsgMessage,
                locale: navigator.language
            };

            if (customMessages.some(({ name }) => name === createEditCustomMsgTitle)) {
                throw new Error("DUPLICATE_TEMPLATE_TITLE")
            }

            const url = `${process.env.REACT_APP_TWOWAYTEXT_API}/upsertTemplate?secretCode=${apiKey}`;
            const res = await axios.post(url, model);

            if (res.status === 200) {
                _handleGetCustomMessages();
                _handleResetCreateEditFields();
                ui.ShowSnackbar("Custom message saved successfully.", Severity.success, 3000);
            }
        } catch (error) {
            switch (error.message) {
                case "DUPLICATE_TEMPLATE_TITLE":
                    ui.ShowSnackbar("Template Title already exist.", Severity.warning)
                    break
                default:
                    ui.ShowSnackbar("Unexpected error saving custom message.", Severity.error);
            }
        }
        ui.HideOverlay();
    }

    const _handleOnCloseCreateEditNewCustomTemplateModal = async() => {
        const confirmBoxMsg = createAction? (<>All entries will be lost. <br /><br />Do you want to continue?</>) : (<>All updated entries will be lost. <br /><br />Do you want to continue?</>);
        let confirmed = await ui.ConfirmBox(confirmBoxMsg);
        if (confirmed == 1) {
            _handleResetCreateEditFields();
        }
    }

    const _handleOnEnableDisableSystemTemplate = async(event, templateId) => {
        const checkedValue = event.target.checked;
        const confirmBoxMsg = (<>Default template will be {checkedValue ? "enabled" : "disabled"}. <br /><br />Do you want to continue?</>);
        let confirmed = await ui.ConfirmBox(confirmBoxMsg);
        if (confirmed == 1) {
            ui.ShowOverlay()
            try {
                const apiKey = `${process.env.REACT_APP_API_KEY}`;
                const url = `${process.env.REACT_APP_TWOWAYTEXT_API}/enableDisableSystemTemplate?templateId=${templateId}&secretCode=${apiKey}&coverKey=${filtersContext.Values.coverKey}&status=${checkedValue ? 1 : 0}`;
                const res = await axios.post(url);
                
                if (res.status === 200) { 
                    _handleGetCustomMessages();
                    _handleResetCreateEditFields();

                    let tmpSystemMessages  = systemMessages
                    tmpSystemMessages.find(p => p.templateId === templateId).status = checkedValue ? "1" : "0"
                    setSystemMessages(tmpSystemMessages)
                    ui.ShowSnackbar("Custom message deleted successfully.", Severity.success, 3000);
                }
            } catch (error) {
                ui.ShowSnackbar("Unexpected error deleting custom message.", Severity.error);
            }
            ui.HideOverlay();
        }
    }    

    const _handleOnDeleteCustomTemplate = async(template) => {
        const confirmBoxMsg = (<>Custom template will be deleted. <br /><br />Do you want to continue?</>);
        let confirmed = await ui.ConfirmBox(confirmBoxMsg);
        if (confirmed == 1) {
            ui.ShowOverlay()
            try {
                const apiKey = `${process.env.REACT_APP_API_KEY}`;
                const url = `${process.env.REACT_APP_TWOWAYTEXT_API}/deleteTemplate?templateId=${template.templateId}&secretCode=${apiKey}`;
                const res = await axios.post(url);
                
                if (res.status === 200) { 
                    _handleGetCustomMessages();
                    _handleResetCreateEditFields();
                    ui.ShowSnackbar("Custom message deleted successfully.", Severity.success, 3000);
                }
            } catch (error) {
                ui.ShowSnackbar("Unexpected error deleting custom message.", Severity.error);
            }
            ui.HideOverlay();
        }
    }

    const _handleResetCreateEditFields = () => {
        setTemplateIdToUpdate(null);
        setCreateEditCustomMsgTitle("");
        setCreateEditCustomMsgMessage("");
        setCharacterCount(0);
        setShowCreateEditCustomTemplateModal(false);
    }

    const handleEmojiAdded = (e) => {
        setCreateEditCustomMsgMessage(createEditCustomMsgMessage + '' + e + ' ');
        createEditCustomMsgInput.current.focus();
    }

    useEffect(() => {
        setCharacterCount(createEditCustomMsgMessage.length);
    }, [createEditCustomMsgMessage]);


    const tokenClick =(token) =>
    {
        const el = document.getElementById('createEditCustomMsgMessage');
        insertTextAtCursor(el, availableTokens[token] );
        //setCreateEditCustomMsgMessage(createEditCustomMsgMessage + '' + availableTokens[token] + ' ');
        createEditCustomMsgInput.current.focus();
    }

    var availTokens = Object.keys(availableTokens).map(function(key) {
        return <div style={{display : "flex", paddingBottom : "5px"}}>
                  <div style={{paddingRight : "5px"}}>{key} =</div><div onClick={() => tokenClick(key)} style={{fontWeight : "bold", cursor : "pointer"}}>{availableTokens[key]}</div>
               </div>
    });
    
    return(
        <div className="TwoWayText">
            <Box p={2} bgcolor="background.paper">
                <Typography variant="h4">Text Message Templates</Typography>
            </Box>
            <Divider />
            <Box>
                <CoverFilter />
            </Box>  
            <Divider />
            { coverSelected ?
                <Box p={2} bgcolor="background.paper">
                    <div className="TwoWayText__Body">
                        <Box p={2} bgcolor="background.paper">
                            <Grid container justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Typography variant="h5">Default Messages</Typography>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem/>
                                <Grid item>
                                    <Typography>View existing default messages</Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Divider />

                        { hasSystemMessages ?
                            <Box bgcolor="background.paper" className="TwoWayText__SystemMessages">
                                {systemMessages.map(d => (
                                    <>
                                    <div className="TwoWayText__SystemMessages--content" key={d.templateId}>
                                        <VisibilityIcon color="primary" onClick={() => _handleOnClickViewSystemMessage(d)}/>
                                        <Switch 
                                            checked={d.status === "1" ? true : false} 
                                            onChange={(event) => _handleOnEnableDisableSystemTemplate(event, d.templateId)}
                                            color="primary"
                                            />
                                        <div>
                                            <Typography>{d.name}</Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    </>
                                ))}
                            </Box>
                            :
                            <>
                            <Box p={4} bgcolor="background.paper">
                                <Grid container justify="flex-start" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Typography variant="h5" color="primary">Selected cover key have no default messages.</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider />
                            </>
                        }


                        <Box pt={2} p={2} bgcolor="background.paper">
                            <Grid container justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item>
                                    <Typography variant="h5">Custom Message Templates</Typography>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem/>
                                <Grid item>
                                    <Typography>Create, view, edit, or delete custom text message templates for your practice</Typography>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" flexItem/>
                                <Grid item direction="row">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        endIcon={<Icon>add</Icon>}
                                        onClick={() => _handleShowCreateEditCustomTemplateModal(true)}
                                    >
                                    Add New
                                    </Button>    
                                </Grid>
            
                            </Grid>
                        </Box>

                        <Divider />

                        { hasCustomMessages ? 
                            <Box bgcolor="background.paper" className="TwoWayText__CustomMessages">
                                {customMessages.map(d => (
                                    <>
                                    <div className="TwoWayText__CustomMessages--content" key={d.templateId}>
                                        <VisibilityIcon color="primary" onClick={() => _handleOnClickViewSystemMessage(d)}/>
                                        <EditIcon color="primary" onClick={() => _handleOnClickEditCustomMessage(d)}/>
                                        <DeleteIcon color="primary" onClick={() => _handleOnDeleteCustomTemplate(d)}/>
                                        <div>
                                            <Typography>{d.name}</Typography>
                                        </div>
                                    </div>
                                    <Divider />
                                    </>
                                ))}
                            </Box>
                        :
                            <Box p={4} bgcolor="background.paper">
                                <Grid container justify="flex-start" alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Typography variant="h5" color="primary">Selected cover key have no custom messages.</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        }

                    </div>
                </Box>
            : null }
            

            <Modal show={showViewOnlyMsgModal} hideCloseButton={false} closeModalOnOutsideClick={true} onClose={() => setShowViewOnlyMsgModal(false)}>
                <div className="modal__content--title">
                    <h1>Title: </h1>
                    <h2>{viewOnlyMsgModalTitle}</h2> 
                </div>

                <div className="modal__content--body">
                    <div className="modal__content--body-title">
                        <h1>Message: </h1>
                    </div>
                    <div className="modal__content--body-description">
                        <Typography>{viewOnlyMsgModalBody}</Typography>
                    </div>
                </div>
            </Modal>

            <Modal show={showCreateEditCustomTemplateModal} hideCloseButton={false} closeModalOnOutsideClick={false} onClose={() => _handleOnCloseCreateEditNewCustomTemplateModal()}>
                <div className="modal__content--title">
                    <h1>{createAction ? "Create" : "Edit"} Custom Message Template</h1>
                </div>

                <ValidatorForm onSubmit={_handleOnSubmitCreateEditCustomTemplate} onError={errors => console.log(errors)} > 
                    <div className="modal__content--body">
                        <div style={{width: "600px", marginTop: "20px"}}>
                            <InputLabel for="createEditCustomMsgTitle" className="modal--label">Title</InputLabel>
                            <TextValidator
                                    id="createEditCustomMsgTitle"
                                    autoComplete="off"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    value={createEditCustomMsgTitle}
                                    onInput={ e=>setCreateEditCustomMsgTitle(e.target.value)}
                                    validators={['required']}
                                    errorMessages={['Title is required']}       
                                    inputProps={{maxLength: 100}}             
                                    />
                        </div> 
                        <div style={{width: "600px", marginTop: "20px"}}>
                            <InputLabel for="createEditCustomMsgMessage" className="modal--label">Message</InputLabel>
                            <TextValidator
                                    id="createEditCustomMsgMessage"
                                    autoComplete="off"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    inputRef={createEditCustomMsgInput}
                                    value={createEditCustomMsgMessage}
                                    onInput={ e=>setCreateEditCustomMsgMessage(e.target.value)}
                                    validators={['required']}
                                    errorMessages={['Message is required']}       
                                    inputProps={{maxLength: 320}}             
                                    />
                            <div className="modal__content__twoway--charcount-emoji">
                                <div><span className='txt--small conversation-input-counter'>{characterCount} / {characterCountMax}</span></div>
                                <div className="emoji"><TwoWayEmoji emojiAdded={handleEmojiAdded}/></div>
                            </div>
                        </div> 
                    </div>

                    
                    <div className="modal__content--body">
                        <div>
                            <h1>Available Tokens: </h1>
                        </div>
						<div>
							To insert a token in a custom message, simply double click on a token below. Please note that these tokens can only be used for covers that have hosted numbers, and not for short codes.
						</div>
						<br></br>
                        <div>
                           <>{availTokens}</>
                        </div>
                    </div>
                
                    <div className="modal__content--buttons">
                        <Button variant="contained" color="primary" type="submit">
                            SUBMIT
                        </Button>  
                        <Button variant="contained" type="reset" onClick={() => _handleOnCloseCreateEditNewCustomTemplateModal()}>
                            CANCEL
                        </Button>  
                    </div>
                </ValidatorForm>
            </Modal>
        </div>
    );
}