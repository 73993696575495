import React from "react";
import { Box, Modal, TextField } from "@material-ui/core";
import TextBoxWithValidator from "../Components/Common/TextBoxWithValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import ViewIcon from "@material-ui/icons/Cancel";
import ToolTipWithIcon from "../Components/Common/ToolTipWithIcon";

export default function PmsDocumentationModal({
  showModal = false,
  rowValue,
  onCloseModal,
}) {
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => onCloseModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Shadow-modal__wrapper"
      >
        <div className="Shadow-modal__container bulk-confirmation__container pmsmodal_container docu">
        <div className="Shadow-modal-title__container docu">
          <span style={{ cursor: "default" }}>CoverConfigSettingsTemplate</span>
          <div>
            <ToolTipWithIcon
              title="Close"
              Icon={ViewIcon}
              onClick={() => onCloseModal(false)}
            />
          </div>
        </div>
          <div className="bulk-confirmation-grid__wrapper">
            <h2>Property Description - {rowValue.property}</h2>
            {/* <TextField
                // id="outlined-multiline-static"
                label="CoverConfigSettingsTemplate"
                // min={4}
                // defaultValue="Default Value"
              /> */}
            <ValidatorForm
              onSubmit={() => console.log("submit")}
              onError={(errors) => console.log(errors)}
            >
              <div className="pms-pmsmodal__wrapper">
                <TextBoxWithValidator
                  required={false}
                  label="PMS Category"
                  readOnly={true}
                  value={rowValue.pmsCategory || ""}
                  name="pmsCategory"
                  onInput={undefined}
                />
                <TextBoxWithValidator
                  required={false}
                  label="Property"
                  readOnly={true}
                  value={rowValue.property || ""}
                  name="Property"
                  onInput={undefined}
                />
                <TextBoxWithValidator
                  required={false}
                  label="Data Type"
                  readOnly={true}
                  value={rowValue.dataType || ""}
                  name="dataType"
                  onInput={undefined}
                />
                <TextBoxWithValidator
                  required={false}
                  label="Dev Only?"
                  readOnly={true}
                  value={rowValue.developerUsageOnly}
                  name="developerUsageOnly"
                  onInput={undefined}
                />
                <TextBoxWithValidator
                  required={false}
                  label="Default Value"
                  readOnly={true}
                  value={rowValue.defaultValue || ""}
                  name="defaultValue"
                  onInput={undefined}
                  multiline={true}
                />
                <TextBoxWithValidator
                  required={false}
                  label="Template Default"
                  readOnly={true}
                  value={rowValue.templateDefault || ""}
                  name="templateDefault"
                  onInput={undefined}
                  multiline={true}
                />
              </div>
              <TextBoxWithValidator
                required={false}
                label="Flag Description"
                readOnly={true}
                value={rowValue.flagDescription || ""}
                name="flagDescription"
                onInput={undefined}
                multiline={true}
              />
            </ValidatorForm>
          </div>
        </div>
      </Modal>
    </>
  );
}
