import React from "react";
import { Grid } from "@material-ui/core";
import TextBoxWithValidator from "../Components/Common/TextBoxWithValidator";
import MaterialCheckBox from "./../Components/Common/MaterialCheckBox";
import MaterialTransferList from "../Components/Common/MaterialTransferList";

export default function HfJobFive({ setValue, mockData, setSelectedCovers }) {
  return (
    <div>
      <div className="shadow-bulk__container">
        <span className="pms-ws_subTitle hf">Send alert Availability Slot Count in Shadow Database Differs From Norm</span>
        <span className="pms-ws_description hf">
        Job runs on a specified schedule to send an email alert - though AppInsights -  if the count of the current day's Availability Slots inserted into the VwAvailabilitySlot table of the cover's active shadow database differs by a pre-specified percentage from the average count of the last three days. Once turned on this check is not immediate as the job needs to run for at least three days to build up a set of reference counts. This job only runs for active shadow covers for which the blade directive includes Default. The threshold difference percentage and specific covers for which the job should be run can be specified in the CSS tool (here).</span>

        <MaterialCheckBox
          onChange={(e) => setValue(e, true)}
          value={mockData.Job05EnableExecution || false}
          name="Job05EnableExecution"
          label="Enable execution?"
        />
        <Grid
          container
          alignItems="center"
          spacing={1}
          style={{ display: "flex", alignItems: "baseline" }}
        >
          <Grid item sm={6}>
            <div className="actionButton__container">
              <TextBoxWithValidator
                label="Threshold percentage"
                fullWidth={true}
                value={mockData.job05AvSlotThresholdPercentage || ""}
                onInput={(e) => setValue(e)}
                name="job05AvSlotThresholdPercentage"
                id="job05AvSlotThresholdPercentage"
              />
            </div>
          </Grid>
          <Grid item sm={6}>
            <div className="actionButton__container hf">
              <TextBoxWithValidator
                label="Days to Keep Records"
                fullWidth={true}
                value={mockData.job05AvSlotDaysRecord || ""}
                onInput={(e) => setValue(e)}
                name="job05AvSlotDaysRecord"
                id="job05AvSlotDaysRecord"
              />
            </div>
          </Grid>
          <Grid item sm={12} className="pms_jobfiveTransferList">
            <MaterialTransferList
              mockData={mockData}
              setSelectedCovers={setSelectedCovers}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
