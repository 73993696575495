import { createTheme } from "@material-ui/core";

const MUI_Defaults = createTheme({
  props: {
    MuiSelect: {
      MenuProps: {
        style: {
          position: "absolute",
        },
        PaperProps: {
          style: {
            translate: "0px 35%",
            maxHeight: "240px",
            overflowY: "auto",
          },
        },
      },
    },
  },
});

export default MUI_Defaults;
