import React from "react";
import { Box, Modal, TextField } from "@material-ui/core";
import TextBoxWithValidator from "../Components/Common/TextBoxWithValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import ViewIcon from "@material-ui/icons/Cancel";
import ToolTipWithIcon from "../Components/Common/ToolTipWithIcon";

export default function PmsDocumentationJsonTemplate({
  showModal = false,
  jsonTemplate,
  onCloseModal,
}) {
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => onCloseModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Shadow-modal__wrapper docu"
      >
        <div className="Shadow-modal__container bulk-confirmation__container pmsmodal_container docu">
        <div className="Shadow-modal-title__container docu">
          <span style={{ cursor: "default" }}>CoverConfigSettingsTemplate</span>
          <div>
            <ToolTipWithIcon
              title="Close"
              Icon={ViewIcon}
              onClick={() => onCloseModal(false)}
            />
          </div>
        </div>
          <div className="bulk-confirmation-grid__wrapper">
            <div className="pms-pmsConnectionString__wrapper">
              <Box
                component="form"
                sx={{ "& .MuiTextField-root": { m: 1 } }}
                noValidate
                autoComplete="off"
              >
                <div className="pms-pmsConnectionString__text-wrapper">
                  {jsonTemplate}
                </div>
              </Box>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
